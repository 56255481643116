<template>
  <!--div class="about">
    <h1>Règle du jeu</h1>

    <p>En tant que membre de l’équipe «&nbsp;Expérience patient&nbsp;» vous allez écouter monsieur Gilles B. évoquer son
      expérience d’une intervention chirurgicale en Hôpital de jour. Le parcours qu’il a suivi est divisé en trois
      parties, une partie amont comprenant 3 étapes (Consultation de chirurgie – Préadmission – Consultation
      d’anesthésie), une partie Hôpital de jour&nbsp;comprenant 5 étapes (Arrivée – Préparation – Intervention – Attente
      sortie – Sortie) et une partie aval&nbsp;comprenant 2 étapes (Domicile – Consultation post-opératoire). </p>

    <p>Le récit de Gilles B. est divisé en séquences de moins de 2 minutes chacune disposées selon l’ordre chronologique du
      parcours qu’il a suivi. Les deux première séquences sont destinées à vous montrer comment procéder et affiche
      automatiquement le résultat dans les cases correspondantes. Vous pourrez ensuite aborder la première partie du
      parcours suivi par le patient.</p>
    <p class="hilited_paragraph">
      Votre mission consiste à identifier, dans chacune des dix étapes du parcours, ce qu’il convient de préserver ou au
      contraire d’améliorer pour accroître la satisfaction que les futurs patients tireront de leur expérience.</p>
    
    <p>Pour écouter le récit de chaque étape il vous suffit de cliquer sur le bouton «&nbsp;ECOUTER&nbsp;». Si vous en éprouvez le
      besoin, vous pouvez accéder à la transcription de la séquence sous forme de texte en cliquant sur le bouton
      «&nbsp;TEXTE&nbsp;».</p>
    <p>
      Pour remplir votre mission, vous disposez de mots clés, distribués en 6 Familles (Prise en charge – Attitude des
      professionnels – Explications – Documents – Organisation – Infrastructures) de 4 mots chacune. Il vous suffit de
      choisir le mot clé dans la famille qui correspond au point que vous avez détecté et de le déplacer dans un des
      deux espaces qui vous sont proposés – «&nbsp;A préserver&nbsp;» ou «&nbsp;A améliorer&nbsp;».</p>
    <p>
      Pour passer d’une séquence à la suivante vous devez cliquer sur le bouton «&nbsp;SUITE». Vos choix sont enregistrés et
      vous ne pouvez plus revenir en arrière. Lorsque vous avez validé la dernière séquence de chaque partie du parcours
      (Amont – Ambulatoire – Aval), l’ensemble des mots clés que vous avez sélectionnés et leur répartition est présenté
      à Gilles B.</p>
    <p>
      Un score d’écoute vous indique la pertinence de ceux-ci, accompagné de suggestions. Si le score obtenu est
      satisfaisant il vous suffit de cliquer sur le bouton «&nbsp;Suite&nbsp;» pour passer à la partie suivante du parcours. Dans
      le cas contraire vous pouvez suivre la suggestions qui vous sera faite pour améliorer celui-ci.</p>
    <p>
      Lorsque vous avez terminé l’ensemble du parcours, votre taux d’écoute final s’affiche. Vous pouvez alors consulter
      le récapitulatif des points que vous avez détectés, comparé à ceux que vous auriez dû éventuellement détecter.</p>

    <p>
      <button class="big" @click="$router.push('/partA/partABD')">C'est parti !</button>
    </p>
  </div-->


  <div class="subpart">
    <img src="../../public/img/rule01.png" v-if="subpart_ === 'partRA01'" subpart="partRA01" :key="subpart_"/>
    <img src="../../public/img/rule02.png" v-if="subpart_ === 'partRA02'" subpart="partRA02" :key="subpart_"/>
    <img src="../../public/img/rule10.png" v-if="subpart_ === 'partRA10'" subpart="partRA10" :key="subpart_"/>
    <img src="../../public/img/rule11.png" v-if="subpart_ === 'partRA11'" subpart="partRA11" :key="subpart_"/>
    <img src="../../public/img/rule12.png" v-if="subpart_ === 'partRA12'" subpart="partRA12" :key="subpart_"/>
    <img src="../../public/img/rule13.png" v-if="subpart_ === 'partRA13'" subpart="partRA13" :key="subpart_"/>

    <img src="../../public/img/rule20.png" v-if="subpart_ === 'partRA20'" subpart="partRA20" :key="subpart_"/>
    <img src="../../public/img/rule30.png" v-if="subpart_ === 'partRA30'" subpart="partRA30" :key="subpart_"/>
    <img src="../../public/img/rule40.png" v-if="subpart_ === 'partRA40'" subpart="partRA40" :key="subpart_"/>

    <img src="../../public/img/conclusionA.png" v-if="subpart_ === 'conclusionA'" subpart="conclusionA"
         :key="subpart_"/>

    <img src="../../public/img/rule50.png" v-if="subpart_ === 'partRB50'" subpart="partRB50" :key="subpart_"/>
    <img src="../../public/img/rule52.png" v-if="subpart_ === 'partRB52'" subpart="partRB52" :key="subpart_"/>

    <div v-if="subpart_ === 'partRB55'" subpart="partRB55" :key="subpart_" class="subpart_div">
      <h2 class="white_title" data-v-29bd56fe="">L'action</h2>
      <h3 class="white_title" data-v-29bd56fe="">Principe du jeu</h3>
      <hr/>
      <img src="../../public/img/rule55.png"/>
      <hr/>
      <div class="expert_on_the_left">
        Pour écouter la synthèse puis suivre la démonstration, cliquez sur <span class="emphase">suivant</span>.
      </div>
    </div>

    <div v-if="subpart_ === 'partRB56'" subpart="partRB56" :key="subpart_">
      <h2 class="white_title" data-v-29bd56fe="">L'action</h2>
      <h3 class="white_title" data-v-29bd56fe="">Synthèse des entretiens</h3>
      <hr/>
      <img src="../../public/img/rule56.png"/>
      <center>
      <av-waveform class="sentence_listen_player" controlsList="nodownload" ref="player"
                   audio-src="../audio/partB01.mp3"
      ></av-waveform>
      </center>
      <hr/>
      <div class="expert_on_the_left">
        Utilisez le lecteur ci-dessus pour écouter la synthèse. Lorsque vous avez terminé, cliquez sur <span
          class="emphase">suivant</span> pour accéder à la démonstration.
      </div>
    </div>

    <div v-if="subpart_ === 'partRB57'" subpart="partRB57" :key="subpart_">
      <h2 class="white_title" data-v-29bd56fe="">L'action</h2>
      <h3 class="white_title" data-v-29bd56fe="">Synthèse des entretiens</h3>
      <hr/>
      <img src="../../public/img/rule57.png" subpart="partRB57" :key="subpart_"/>
      <hr/>
      <div class="expert_on_the_left">
        Lorsque vous avez terminé, cliquez sur <span class="emphase">suivant</span> pour accéder à la démonstration.
      </div>
    </div>

    <img src="../../public/img/rule60.png" v-if="subpart_ === 'partRB60'" subpart="partRB60" :key="subpart_"/>

    <img src="../../public/img/conclusionB.png" v-if="subpart_ === 'conclusionB'" subpart="conclusionB"
         :key="subpart_"/>

    <div v-if="subpart_ === 'partRA14'" subpart="partRA14" :key="subpart_" class="subpart_div">
      <h2 class="white_title" data-v-29bd56fe="">L'écoute</h2>
      <h3 class="white_title" data-v-29bd56fe="">Principe du jeu</h3>
      <hr/>
      <img src="../../public/img/rule14.png"/>
      <hr/>
      <div class="expert_on_the_left">
        Votre parcours débutera par une première démonstration. Pour la commencer, cliquez sur <span class="emphase">suivant</span>.
      </div>
    </div>
    <hr/>
  </div>

  <div class="bottom_buttons">
    <button v-if="subpart_ === 'partRB56'" class="big" @click="this.$router.push('/about/partRB57')">Texte</button>
    <button v-if="subpart_ === 'partRB57'" class="big" @click="this.$router.push('/partB/partB02')">Suivant</button>
    <button v-if="subpart_ !== 'partRB57'" class="big" @click="nextsubpart">Suivant</button>
  </div>
</template>

<script>

export default {
  name: "about",
  props: ['subpart'],
  data() {
    return {
      subpart_: this.subpart,
    }
  },
  methods: {
    changestage() {
      console.log('changestage?!?');
    },
    nextsubpart() {
      console.log('sending nextsubpart from about');
      this.$emit('nextsubpart', '/about/' + this.subpart_);
    },
  },
  emits: ['nextsubpart'],
  /*components: {
    subpartRA01,
  },*/
  created() {
    // console.log('Preloading images');
    this.$watch(
        () => this.$route,
        () => {
          // console.log('Parent watched route', this.subpart, this.subpart_, this.$route);
          this.subpart_ = this.$route.params.subpart;
          // this.$emit('reloadkeywords');
          // console.log('↳ children:', this.$refs);
        },
        {immediate: true}
    );
    // console.log('watching route', this.subpart, this.subpart_);
  },
};


</script>

<style scoped>

.subpart IMG {
  /*aspect-ratio: 1000/438;*/
}
</style>